











































import Vue from 'vue'
import StockItemRow from '@/views/stocks/StockItemRow.vue'
import { ApiResponse, RequisitionItem } from '@/interfaces'
import { stockStatuses } from '@/formHelpers'
import { mapState } from 'vuex'

interface Parameter {
  search?: string | null
  page?: string | null
  rubriques?: string[] | null
  stockStatuses?: string[] | null
  entryDate?: string[] | null
  itemNumber?: string | null
  stockId?: string | null
  contactId?: string | null
  sale?: number | null
  structure?: string | null
}

export default Vue.extend({
  name: 'StockItems',
  components: { StockItemRow },
  props: {
    contact: {
      type: String,
      required: false,
      default: null,
    },
    itemsToEdit: {
      type: Array,
      required: false,
      default: null,
    },
    parentPage: {
      type: String,
      required: false,
      default: null,
    },
    displayDate: {
      type: String,
      required: false,
      default: 'in',
    },
    refreshTrigger: {
      type: Boolean,
      required: false,
      default: false,
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      search: '',
      page: '1',
      activePage: 1,
      itemsTotal: 0,
      numPages: 1,
      filteredRubriques: [] as string[],
      stockStatuses,
      filteredStockStatuses: ['entry', 'expertise', 'removed', 'not_shown'] as string[],
      linkedRubriques: ['MAR', 'MANR'],
      cascaderProps: { multiple: true },
      entryPeriod: [] as string[],
      itemNumberFilter: '',
      stockIdFilter: '',
      structureFilter: null as string | null,
      saleFilter: null as number | null,
      rubriques: [],
      items: [] as RequisitionItem[],
      itemsId: [] as number[],
      itemsChecked: 0,
      checkAll: false,
      indeterminate: false,
      lastCheckedIndex: -1,
      checkAction: '',
      emptyListDescription: 'La liste des stocks est vide.',
      displayDateText: "d'entrée",
    }
  },
  computed: {
    ...mapState(['user', 'scrollPosition']),
  },
  watch: {
    user(newVal) {
      if (newVal) {
        this.rubriques = newVal.client.config.rubriques
      }
    },
    items: {
      deep: true,
      immediate: true,
      handler() {
        this.indeterminate = false
        this.checkAll = false
        if (this.itemsChecked === this.items.length) {
          this.checkAll = true
        } else {
          if (this.itemsChecked > 0) {
            this.indeterminate = true
          }
        }
      },
    },
    activeTab(newVal) {
      if (newVal === 'items' && this.items.length === 0) {
        this.emptyListDescription = 'La liste des lots est vide.'
        this.fetchItems()
      }
    },
    parentPage(newVal) {
      if (newVal === 'stockItemsList') {
        this.fetchItems()
      }
    },
    '$route.query': {
      deep: true,
      immediate: true,
      handler(query) {
        if (query.search) {
          this.search = query.search
        }
        if (query.itemNumber) {
          this.itemNumberFilter = query.itemNumber
        }
        if (query.stockId) {
          this.stockIdFilter = query.stockId
        }
        if (query.sale) {
          this.saleFilter = parseInt(query.sale) ?? null
        }
        if (query.entryDate) {
          this.entryPeriod = query.entryDate as string[]
        }
        if (query.structure) {
          this.structureFilter = query.structure
        }
        if (query.rubriques) {
          this.initRubriques()
        }
        if (query.stockStatuses) {
          this.initStockStatuses()
        }
        if (query.page !== undefined && this.parentPage === 'stockItemsList') {
          this.page = query.page
          this.fetchItems()
        }
      },
    },
    refreshTrigger() {
      if ((this.contact && this.items.length > 0) || this.parentPage === 'stockItemsList') {
        this.fetchItems()
      }
    },
  },
  mounted() {
    if (this.user) {
      this.rubriques = this.user.client.config.rubriques
    }

    if (this.$route.query.page) {
      this.page = this.$route.query.page as string
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search as string
    }

    if (this.$route.query.entryDate) {
      this.entryPeriod = this.$route.query.entryDate as string[]
    }

    if (this.$route.query.itemNumber) {
      this.itemNumberFilter = this.$route.query.itemNumber as string
    }

    if (this.$route.query.stockId) {
      this.stockIdFilter = this.$route.query.stockId as string
    }

    if (this.$route.query.sale) {
      this.saleFilter = parseInt(this.$route.query.sale as string)
    }

    if (this.$route.query.rubriques) {
      this.initRubriques()
    }

    if (this.$route.query.stockStatuses) {
      this.initStockStatuses()
    }

    if (this.$route.query.structure) {
      this.structureFilter = this.$route.query.structure as string
    }

    if (this.items.length === 0 && this.contact) {
      this.emptyListDescription = 'La liste des lots est vide.'
      this.fetchItems()
    }

    switch (this.displayDate) {
      case 'in':
        this.displayDateText = "d'entrée"
        break

      case 'out':
        this.displayDateText = 'de sortie'
        break
    }
  },
  methods: {
    toggleCheckAll(status: boolean) {
      this.indeterminate = false
      this.items.forEach((item) => {
        this.$set(item, 'isChecked', status)
        if (status) {
          this.itemsToEdit.push(item.id)
        }
      })

      if (status) {
        this.itemsChecked = this.items.length
        this.$emit('setItemsChecked', this.itemsChecked)
        this.$emit('setSelectedItems', this.itemsToEdit)
      } else {
        this.itemsChecked = 0
        this.$emit('setItemsChecked', this.itemsChecked)
        this.$emit('setSelectedItems', [])
      }
    },
    toggleCheck(item: RequisitionItem, index: number, event: PointerEvent | null) {
      const itemId = item.id ?? -1
      const itemToEditIndex = this.itemsToEdit.findIndex((itemToEditId) => {
        return itemToEditId === itemId
      })
      this.checkAction = itemToEditIndex === -1 ? 'select' : 'deselect'

      if (event?.shiftKey && this.lastCheckedIndex !== -1 && this.checkAction === 'select') {
        let start = this.lastCheckedIndex
        let end = index - 1
        // can select top to bottom or bottom to top
        // always start with lowest value
        if (start > end) {
          start = index + 1
          end = this.lastCheckedIndex
        }

        for (let i = start; i <= end; i++) {
          const currentItem = this.items[i] ?? null
          const currentItemId = currentItem?.id ?? -1
          if (currentItem) {
            this.markSelectDeselect(currentItemId)
            currentItem.isChecked = true
          }
        }
        window?.getSelection()?.removeAllRanges()
      }

      this.markSelectDeselect(itemId)
      this.lastCheckedIndex = index
      item.isChecked = this.checkAction === 'select'

      if (this.itemsToEdit.length === 0) {
        // Reset multiple selection data
        this.lastCheckedIndex = -1
        this.checkAction = ''
      }
    },
    markSelectDeselect(itemId: number) {
      const currentPos = this.itemsToEdit.findIndex((itemToEditId) => itemToEditId === itemId) ?? -1

      if (this.checkAction === 'select' && currentPos === -1) {
        this.itemsToEdit.push(itemId)
        this.itemsChecked++
      } else if (this.checkAction === 'deselect' && currentPos !== -1) {
        this.itemsToEdit.splice(currentPos, 1)
        this.itemsChecked--
      }
      this.$emit('setItemsChecked', this.itemsChecked)
      this.$emit('setSelectedItems', this.itemsToEdit)
    },
    fetchItems() {
      if (!this.busy) {
        this.busy = true

        let params = {} as Parameter
        if (this.contact) {
          params = {
            contactId: this.contact,
          }
        } else {
          params = {
            page: this.page,
            rubriques: this.filteredRubriques,
            stockStatuses: this.filteredStockStatuses,
            entryDate: this.entryPeriod,
            itemNumber: this.itemNumberFilter,
            stockId: this.stockIdFilter,
            sale: this.saleFilter,
            structure: this.structureFilter,
          }
          if (this.search) {
            params.search = this.search
          } else {
            delete params.search
          }
        }

        const loading = this.$loading({
          target: '#stockItems',
          text: 'Chargement des données...',
        })

        this.$api
          .get(`/stocks`, {
            params,
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            // this.updateItems(apiResponse.data.items)
            this.items = apiResponse.data.items
            this.$emit('setPaginationData', apiResponse.data.pagination)
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
            loading.close()

            // Automatically scroll to stored scroll position
            window.setTimeout(() => {
              window.scrollTo({ top: parseInt(this.scrollPosition) })
            }, 400)
          })
      }
    },
    updateItems(items: RequisitionItem[]) {
      this.items = items.reduce((accumulator: RequisitionItem[], updatedItem: RequisitionItem) => {
        let itemToUpdateIndex = accumulator.findIndex((item: RequisitionItem) => item.id === updatedItem.id)
        if (itemToUpdateIndex > -1) {
          const itemData = {
            ...updatedItem,
            stockStatusColor:
              this.stockStatuses.find((stockStatus) => stockStatus.value === updatedItem.stockStatus)?.color ?? '-',
            stockTextColor:
              this.stockStatuses.find((stockStatus) => stockStatus.value === updatedItem.stockStatus)?.textColor ??
              '-',
            stockStatusLabel:
              this.stockStatuses.find((stockStatus) => stockStatus.value === updatedItem.stockStatus)?.label ?? '-',
            isChecked: false,
          }
          accumulator.splice(itemToUpdateIndex, 1, itemData)
        }
        return accumulator
      }, this.items)

      // Resetting arrays
      this.itemsId = []
      items.forEach((item: RequisitionItem) => {
        if (item.id) {
          this.itemsId.push(item.id)
        }
      })
      this.$emit('setItemsTotal', this.items.length)
    },
    initRubriques() {
      this.filteredRubriques = []
      if (typeof this.$route.query.rubriques === 'string') {
        this.filteredRubriques.push(this.$route.query.rubriques)
      } else {
        const queryRubriques: string[] = this.$route.query.rubriques as string[]
        queryRubriques.forEach((rubrique: string) => {
          this.filteredRubriques.push(rubrique)
        })
      }
    },
    initStockStatuses() {
      this.filteredStockStatuses = []
      if (typeof this.$route.query.stockStatuses === 'string') {
        this.filteredStockStatuses.push(this.$route.query.stockStatuses)
      } else {
        const queryStockStatuses: string[] = this.$route.query.stockStatuses as string[]
        queryStockStatuses.forEach((status: string) => {
          this.filteredStockStatuses.push(status)
        })
      }
    },
  },
})
