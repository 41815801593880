









































































import Vue from 'vue'
import HighlightIcon from '@/components/Icons/HighlightIcon.vue'
import { ItemFile, RequisitionItem } from '@/interfaces'
import { paymentStatesList, stockStatuses } from '@/formHelpers'
import { mapState } from 'vuex'

interface Rubrique {
  children?: Rubrique[]
  label: string
  value: string
}

interface Badge {
  color: string
  value: string
  textColor: string
}

interface DropdownAction {
  action: string
  item?: RequisitionItem
  index?: number
  renumberBy?: string
}

export default Vue.extend({
  name: 'StockItemRow',
  components: { HighlightIcon },
  props: {
    item: {
      type: Object as () => RequisitionItem,
      required: false,
      default: null,
    },
    index: {
      type: Number,
      required: true,
    },
    dateType: {
      type: String,
      required: false,
      default: 'in',
    },
  },
  data() {
    return {
      stockStatuses,
      rubriques: [],
      selectedItems: [] as RequisitionItem[],
      lastCheckedIndex: -1,
      isContactBoughtItemsList: false,
      paymentStatesList,
    }
  },
  computed: {
    ...mapState(['user']),
    statusFilters() {
      return [
        { text: 'En cours', value: 'en_cours' },
        { text: 'Clos', value: 'clos' },
      ]
    },
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.$set(this.item, 'isChecked', newVal.isChecked)
      },
    },
    user(newVal) {
      this.rubriques = newVal.client.config.rubriques
    },
    '$route.params': {
      deep: true,
      immediate: true,
      handler(params) {
        if (params && params.id) {
          this.isContactBoughtItemsList = true
        }
      },
    },
  },
  mounted() {
    if (this.user) {
      this.rubriques = this.user.client.config.rubriques
    }
  },
  methods: {
    toggleChecked(item: RequisitionItem, index: number, event: Event | null) {
      this.$emit('toggleCheck', item, index, event)
    },
    editRequisitionItem(item: RequisitionItem) {
      this.$router.push({
        name: 'requisition_item_edit',
        params: {
          itemId: item.id?.toString() ?? '',
        },
        query: {
          backTo: this.$route.name,
          backScrollTo: window.scrollY.toString(),
        },
      })
    },
    getMediaList(item: RequisitionItem, type: string) {
      const medias = [] as string[]

      item.requisitionItemFiles?.forEach((itemFile) => {
        if (itemFile.type === type && itemFile.deletedAt === null) {
          medias.push(itemFile.url)
        }
      })

      return medias
    },
    getThumbnail(files: ItemFile[], type: string) {
      for (let index = 0; index < files.length; index++) {
        const element = files[index]
        if (element.type == type && element.deletedAt == null) {
          return element.urlThumb
        }
      }

      return null
    },
    cleanItemFiles(files: ItemFile[]) {
      return files.filter(this.cleanDeletedFiles).sort(this.sortByPosition)
    },
    cleanDeletedFiles(file: ItemFile) {
      return file.deletedAt !== 'NULL'
    },
    sortByPosition(file1: ItemFile, file2: ItemFile) {
      if (file1.position > file2.position) {
        return 1
      }

      if (file1.position < file2.position) {
        return -1
      }

      return 0
    },
    handleItemCommand(command: DropdownAction) {
      // Edit requisition
      if (command.action == 'edit' && command.item) {
        this.editRequisitionItem(command.item)
      }
    },
    formatDate(str: string, hours = false): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        if (hours) {
          const day = new Intl.DateTimeFormat('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          }).format(date)
          const hour = new Intl.DateTimeFormat('fr-FR', {
            hour: 'numeric',
            minute: 'numeric',
          }).format(date)

          return `${day} à ${hour}`
        } else {
          return new Intl.DateTimeFormat('fr-FR').format(date)
        }
      } else {
        return '-'
      }
    },
    getRubriqueRec(rubriques: Rubrique[], str: string): string {
      let rub = '-'
      rubriques.forEach((rubrique: Rubrique) => {
        if (rubrique.value == str) {
          rub = rubrique.label
        }
        if (rubrique.children && rub == '-') {
          rub = this.getRubriqueRec(rubrique.children, str)
        }
      })

      return rub
    },
    getStatusBadge(status: string): Badge {
      const badge = {} as Badge

      badge.color = this.stockStatuses.find((stockStatus) => stockStatus.value === status)?.color ?? '#EFE6FD'
      badge.textColor = this.stockStatuses.find((stockStatus) => stockStatus.value === status)?.textColor ?? '#333333'
      badge.value = this.stockStatuses.find((stockStatus) => stockStatus.value === status)?.label ?? '-'

      return badge
    },
    getItemPaymentState(value: string): Record<string, string | null> | null {
      return (
        this.paymentStatesList.find((paymentState) => paymentState.value === value) ?? {
          text: 'Non soldé',
          value: 'unpaid',
          tag: 'danger',
        }
      )
    },
    getItemPaymentStateLabel(paymentState: string): string | null {
      const invoicePaymentState = this.getItemPaymentState(paymentState)
      if (invoicePaymentState) {
        return invoicePaymentState.text
      }
      return ''
    },
    getStorageArea(item: RequisitionItem): string {
      return item.storageArea ?? '-'
    },
    getEstimationPrice(item: RequisitionItem): string {
      let estimationPrice = ''

      if (item.estimationLow !== null) {
        estimationPrice = `${item.estimationLow} €`
      }

      if (item.estimationHigh !== null) {
        estimationPrice = estimationPrice + ` - ${item.estimationHigh} €`
      }

      return estimationPrice
    },
    isHighlight(item: RequisitionItem) {
      return item.highlight == true
    },
    getStructureColor(item: RequisitionItem) {
      return item.requisition?.project?.structure?.color
    },
  },
})
